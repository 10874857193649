import { getAuthApi } from 'config'
// import queryString from 'query-string'

export async function createTemplate(text) {
  // const params = queryString.stringify()
  let res = await getAuthApi().post(`/Template/createTemplate`, { text: text })
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function getTemplateById(id) {
  let res = await getAuthApi().get(`/Template/getTemplateById?templateId=${id}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function getTemplates(filters) {
  // const params = filters && Object.keys(filters) ? queryString.stringify(filters) : ''
  let res = await getAuthApi().get(`/Template/getTemplates`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data.records
}

export async function updateTemplate(template) {
  // const params = queryString.stringify({ templateId: template.id, templateText: template.text })
  // debugger
  let res = await getAuthApi().put(`/Template/updateTemplate?templateid=${template.id}`, { text: template.text })
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function deleteTemplate(id) {
  let res = await getAuthApi().delete(`/Template/deleteTemplate?templateId=${id}`)
  if (res.status !== 204) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
