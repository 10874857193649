import React, { Component } from 'react'
import styles from './styles.module.scss'

export default class Checkbox extends Component {
  render() {
    return (
      <div key={this.props.id} className={styles[this.props.type]}>
        <input
          id={this.props.id}
          checked={this.props.checked}
          type="checkbox"
          onChange={e => this.props.onChange(!this.props.checked)}
          className={styles.input}
        />
        <label htmlFor={this.props.id} className={styles.label}>
          {this.props.label}
        </label>
      </div>
    )
  }
}
