import { api, getAuthApi } from '../config'
import store from '../store'
import { actionTypes as accountActionTypes } from '../store/reducers/account'
import history from '../utils/history'

export function setAccount(account, rememberMe) {
  store.dispatch({ type: accountActionTypes.SET, payload: account })
  localStorage.setItem('account', JSON.stringify(account))
  if (rememberMe) {
    localStorage.setItem('rm', 'true')
  }
}

export async function refreshAccessToken(token) {
  let res = await api.get(`/Account/refreshAccessToken?token=${token}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Can't login`)
  return res.data
}

export async function unsetAccount(error) {
  try {
    store.dispatch({ type: accountActionTypes.UNSET })
    localStorage.removeItem('account')
    localStorage.removeItem('rm')
  } catch (error) {
    console.log(error.message)
  }
  history.replace('/login')
}

export const getStoredAccount = async function() {
  let account = store.getState().account // get user from redux
  if (account && account.accessToken) {
    return account
  } else {
    account = await localStorage.getItem('account') //get from localstorage
    if (account) {
      account = JSON.parse(account)
      account = await refreshAccessToken(account.refreshToken)
      store.dispatch({ type: accountActionTypes.SET, payload: account }) //set to redux
    }
  }
  return account
}

export async function login(email, password, rememberMe) {
  let res = await api.post('/Account/login', { email: email, password: password })
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Can't login`)
  setAccount(res.data, rememberMe)
  return res.data
}

export async function logout() {
  let res = await getAuthApi().get('/Account/CancelAccessToken')
  if (res.status !== 204) throw new Error(res.data && res.data.Message ? res.data.Message : `Can't login`)
  await unsetAccount()
  return res.data
}

export async function register(account) {
  let res
  // code = 201
  if (account.id) {
    const id = account.id
    delete account.id
    res = await getAuthApi().put(`/Account/editUserInfo?userId=${id}`, {
      phoneNumber: account.phoneNumber,
      clientId: account.clientId,
    })
  } else {
    res = await getAuthApi().post('/Account/registration', account)
  }

  if (res.status !== 201) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function getAllUsers(clientId) {
  const params = clientId ? `?clientId=${clientId}` : ''
  let res = await getAuthApi().get(`/Account/getAllUsers${params}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
