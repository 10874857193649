import React, { Component } from 'react'
import { Button, Select, Table, DatePicker, Dropdown, Menu, Icon, Input, message, Modal, Radio, Tooltip } from 'antd'
import SelectCheckItem from 'components/Checkbox/selectCheckItem'

import setPageProps from 'actions/pageProps'
import * as customersActions from 'actions/customer'
import { isTablet } from 'react-device-detect'

import AddButton from 'components/AddButton'
import { CUSTOMER_STATUSES } from 'config'

import moment from 'moment'
import history from 'utils/history'
import { connect } from 'react-redux'
import * as clientActions from 'actions/client'
import * as telegramActions from 'actions/telegram'
import * as campaignsActions from 'actions/campaign'
import * as citiesAction from 'actions/city'
import * as whatsappActions from 'actions/whatsapp'
import * as templatesActions from 'actions/templates'
import * as tagsActions from 'actions/tags'

import i18n from 'sources/i18n'

import styles from './styles.module.scss'
import Pen from 'sources/img/pen.png'
import Unsubscribe from 'sources/img/unsubscribe.png'

const POLLING_STATUSES = {
  INIT: 0,
  HANDLING: 1,
  SUCCESS: 2,
  EXCEPTION: 3,
}

const dropdownStyle = {
  width: '4rem',
  margin: '5px 10px',
  borderRadius: '5px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#a0a0a0',
  textAlign: 'center',
}

class Customers extends Component {
  state = {
    customers: [],
    statusFilter: 4,
    showNewMenu: false,
    selectedCustomers: [],
    showTemplateModal: false,
    phoneHash: null,
    phoneNumber: null,
    showSendCode: false,
    loading: true,
    templates: [],
    campaigns: [],
    campaign: null,
    cities: [],
    cityParams: {
      pageLength: 10,
      pageNumber: 1,
      name: null,
    },
    tags: [],
    customerTags: [],
    totalRecords: 0,
    filters: {
      pageLength: 100,
      pageNumber: 1,
      birthdayDate: null,
      cities: [],
      tagsIds: [],
      startAge: null,
      endAge: null,
      male: null,
      female: null,
      status: null,
      search: null,
    },
  }
  columns = [
    // {
    //   title: '▢',
    //   key: 'checked',
    //   dataIndex: 'checked',
    //   render: (text, record, index) => (
    //     <Checkbox
    //       type="blue"
    //       id={record.id}
    //       indeterminate={true}
    //       checkAll={false}
    //       onChange={() => this.selectCustomer(record.id)}
    //       checked={this.state.selectedCustomers.includes(record.id)}
    //     />
    //   ),
    // },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      // render: (record, data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'First Name',
      key: 'first_name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: '',
    },
    {
      title: 'Birthday',
      dataIndex: 'birthdayDate',
      key: '',
      render: (record, data, index) => <span>{moment(record).format('YYYY/MM/DD')}</span>,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: '',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: '',
      render: record => {
        return <span>{record === 1 ? i18n.t('customers.male') : i18n.t('customers.female')}</span>
      },
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: record => <span>{CUSTOMER_STATUSES[record] ? CUSTOMER_STATUSES[record] : 'New'}</span>,
    },
    {
      title: '',
      key: '',
      width: isTablet ? 60 : 317,
      render: (record, data) =>
        isTablet ? (
          <div className={styles.buttons}>
            <Button onClick={() => this.unsubscribe([data.id])}>
              <img src={Unsubscribe} alt="unsubscribe" />
            </Button>
            <Button onClick={() => history.push('customer', { customer: data })}>
              <img src={Pen} alt="edit" />
            </Button>
          </div>
        ) : (
          <div className={styles.buttons}>
            {data.status === 0 && (
              <Button onClick={() => this.unsubscribe([data.id])}>{i18n.t('customers.unsubscribe')}</Button>
            )}
            <Button onClick={() => history.push('customer', { customer: data })}>{i18n.t('customers.edit')}</Button>
          </div>
        ),
    },
  ]

  unsubscribe = async data => {
    try {
      await telegramActions.unsubscribe(data)
      message.success('Customer was unsubscribed')
      await this.getCustomers()
    } catch (error) {
      message.error(error.message)
    }
  }

  handleTableChange = async (current, filters, sorter) => {
    this.setFilter('pageNumber', current.current)
  }

  selectCustomer = id => {
    let { selectedCustomers } = this.state
    if (selectedCustomers.includes(id)) {
      selectedCustomers = selectedCustomers.filter(cid => cid !== id)
    } else {
      selectedCustomers.push(id)
    }
    this.setState({
      selectedCustomers: selectedCustomers,
      customerTags: this.state.tags.filter(tag => tag.customerId === id),
    })
  }

  getCustomers = async clientId => {
    this.setState({ loading: true })
    let customers = []
    try {
      let filters = {}

      Object.keys(this.state.filters).forEach(key => {
        if ((this.state.filters[key] || this.state.filters[key] === 0) && this.state.filters[key] !== null) {
          filters[key] = this.state.filters[key]
        }
      })

      customers = await customersActions.getCustomers({ ...filters, clientId: this.props.client.id })
      if (this.state.filters.pageNumber > customers.pageInfo.totalRecords && this.state.filters.pageNumber !== 1) {
        // this.setState({ filters: {..filters, pageNumber: } })
        this.setFilter('pageNumber', customers.pageInfo.totalRecords ? customers.pageInfo.totalRecords : 1)
      }
      this.setState({ customers: customers.records, totalRecords: customers.pageInfo.totalRecords })
    } catch (e) {
      message.error(e.message)
    } finally {
      this.setState({ loading: false })
    }
  }

  changePageLength = async value => {
    let { filters } = this.state
    filters.pageLength = parseInt(value)
    this.setState({ filters: filters }, await this.getCustomers())
  }

  setFilter = async (name, value) => {
    let { filters } = this.state
    if (name === 'status') {
      // this.setState({ statusFilter: value })
      // debugger
      if (filters[name] === value) {
        delete filters[name]
      } else {
        filters[name] = value
      }
    } else if (name === 'birthDayDate') {
      filters[name] = value ? moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) : null
    } else {
      filters[name] = value
    }
    // console.log(filters)
    this.setState({ filters: filters })
    await this.getCustomers(this.state.clientId)
  }

  getCities = async () => {
    try {
      const cities = await citiesAction.getCities(this.state.cityParams)
      this.setState({ cities: cities })
    } catch (error) {}
  }

  componentDidMount = async () => {
    setPageProps({
      title: i18n.t('customers.list_of_customers'),
      buttons: true,
      refresh: async () => await this.getCustomers(),
      search: async v => this.setFilter('search', v),
      sendInvitation: async () => this.sendInvitation(),
    })

    try {
      let clientId = this.props.client.id
      const locationParams = this.props.location.state
      if (locationParams) {
        if (locationParams.clientId) {
          clientId = locationParams.clientId
        }
      } else {
        const client = await clientActions.getClientByUserId()
        clientId = client.id
      }
      await this.getCustomers(clientId)
      const campaigns = await campaignsActions.getCampaigns({
        newFirst: true,
        pageNumber: 1,
        pageLength: 100,
        clientId: clientId,
      })

      await this.getCities()
      const tags = await tagsActions.getAllTags(clientId)
      const templates = await templatesActions.getTemplates()
      // const templates = [{ id: 12, text: '123' }, { id: 13, text: '313' }]
      this.setState({
        campaigns: campaigns.records,
        tags: tags,
        loading: false,
        clientId: clientId,
        templates: templates && templates.length ? templates : [],
        campaign: locationParams && locationParams.campaign ? locationParams.campaign : null,
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  sendVerificationCode = async () => {
    try {
      let phoneNumber = this.state.phoneNumber ? this.state.phoneNumber.replace(/\D/g, '') : ''
      if (!phoneNumber || phoneNumber.length < 8) {
        message.error('Wrong phone number')
      } else {
        const hash = await telegramActions.sendVerificationCode(phoneNumber)
        if (hash) {
          this.setState({ phoneHash: hash })
        } else {
          message.error('Error with getting verification code')
          this.setState({ showSendCode: false })
        }
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  authorizeTelegram = async () => {
    try {
      if (!this.state.code) {
        message.error('Wrong code')
      } else {
        await telegramActions.authorize({
          code: this.state.code,
          hash: this.state.phoneHash,
          phoneNumber: this.state.phoneNumber,
        })
        message.success('Authorized')
        this.setState({ showSendCode: false, code: null, hash: null, phoneNumber: null })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  sendInvitation = async key => {
    this.setState({ sendingInvitation: true })
    try {
      if (this.state.selectedCustomers && this.state.selectedCustomers.length) {
        if (key.key === '1') {
          // Telegram
          const customer = this.state.customers.find(customer => this.state.selectedCustomers.includes(customer.id))
          if (customer && customer.phoneNumber) {
            await telegramActions.sendInviteForUser(this.state.clientId, customer.phoneNumber)
          }
        } else {
          if (!this.state.template) {
            this.setState({ sendingInvitation: false })
            message.error('Select template!')
            return
          }

          const pollingId = await whatsappActions.sendMessageTemplateList({
            clientId: this.state.clientId,
            customerIds: this.state.selectedCustomers,
            templateId: this.state.template,
          })
          let res
          const checkStatusInterval = setInterval(async () => {
            res = await whatsappActions.getSendMessageState(pollingId)
            switch (res.handlerStatus) {
              case POLLING_STATUSES.EXCEPTION:
                message.error(`Error sending invitations: ${res.exceptionMessage}`)
                clearInterval(checkStatusInterval)
                break
              case POLLING_STATUSES.SUCCESS:
                const customers = res.customers || []
                const successCount = customers.filter(customer => customer.status === 1).length
                const exceptionCustomers = customers.filter(customer => customer.status === 2)
                if (exceptionCustomers.length) {
                  console.log('Invitation failed for: ', exceptionCustomers)
                }
                message.success(
                  `Invitations sent to ${successCount} customers, did not dend to ${
                    exceptionCustomers.length
                  }  customers`
                )
                clearInterval(checkStatusInterval)
                break
              default:
                break
            }
          }, 1000)
        }
        // message.success('Invitation was send')
        // this.setState({ selectedCustomers: [] })
        await this.getCustomers()
      } else {
        message.error('Customers not selected')
      }
    } catch (error) {
      message.error(error.message)
    }
    this.setState({ sendingInvitation: false })
  }

  sendMessage = async () => {
    try {
      this.setState({ sendingCampaign: true })
      if (this.state.selectedAllCustomers) {
        await telegramActions.sendMessage(this.state.campaign)
      } else {
        await telegramActions.sendMessageList(this.state.campaign, this.state.selectedCustomers)
      }

      message.success('Campaign sent')
    } catch (error) {
      message.error(error.message)
    } finally {
      this.setState({ sendingCampaign: false })
    }
  }

  fetchCity = async value => {
    this.setState({ cityParams: { ...this.state.cityParams, name: value } }, await this.getCities())
  }

  fetchTag = value => {}

  render() {
    const invitationMenu = (
      <Menu onClick={this.sendInvitation}>
        <Menu.Item
          key="1"
          style={{ color: '#222222', letterSpacing: '1px', fontSize: '1.5rem', fontFamily: 'SFProDisplay' }}
        >
          Telegram
        </Menu.Item>
        <Menu.Item
          key="2"
          style={{ color: '#222222', letterSpacing: '1px', fontSize: '1.5rem', fontFamily: 'SFProDisplay' }}
        >
          WhatsApp
        </Menu.Item>
      </Menu>
    )
    const citiesOpt = (
      <Menu>
        {this.state.cities.map(city => (
          <SelectCheckItem
            id={city.id}
            key={city.id}
            title={city.name}
            checked={this.state.filters.cities.includes(city.name)}
            onChange={async e => {
              let cities = this.state.filters.cities
              if (e) {
                cities.push(city.name)
              } else {
                cities = cities.filter(c => city.name !== c)
              }
              this.setFilter('cities', cities)
            }}
          />
        ))}
      </Menu>
    )
    const selectedRowKeys = this.state.selectedCustomers
    const rowSelection = {
      selectedRowKeys,
      onSelectAll: row => {
        this.setState({ selectedAllCustomers: row })
      },
      onChange: row => {
        this.setState({ selectedCustomers: row, selectedAllCustomers: row.length === this.state.customers.length })
      },
    }

    const campaigns = this.state.campaigns.map(campaign => (
      <Select.Option key={campaign.id} title={campaign.name} value={campaign.id}>
        {campaign.name}
      </Select.Option>
    ))

    const templates = this.state.templates.map((template, idx) => {
      // const text = template.text ? template.text.replace(/\n/g, '<br/>') : ''
      // const lines = template.text ? template.text.split('\n') : []
      return (
        <div key={`template_${idx}`} className={styles.radioGroup}>
          <Radio value={template.id} />
          <div>
            <div className={styles.radioText}>{template.text}</div>
            {/* {lines.map(line => (
              <div className={styles.radioText}>{line}</div>
            ))} */}
          </div>
        </div>
      )
    })

    const ageMenu = (
      <Menu>
        <input
          onBlur={e => this.setFilter('startAge', e.target.value)}
          min={0}
          style={dropdownStyle}
          type="number"
          max={this.state.filters.endAge ? this.state.filters.endAge : 100}
        />
        -
        <input
          onBlur={e => this.setFilter('endAge', e.target.value)}
          min={this.state.filters.startAge ? this.state.filters.startAge : 0}
          max={100}
          type="number"
          style={dropdownStyle}
        />
      </Menu>
    )
    const genderMenu = (
      <Menu>
        <SelectCheckItem id="male" title="Male" onChange={() => this.setFilter('male', !this.state.filters.male)} />
        <SelectCheckItem
          id="female"
          title="Female"
          onChange={() => this.setFilter('female', !this.state.filters.female)}
        />
      </Menu>
    )
    const statusMenu = Object.values(CUSTOMER_STATUSES).map((item, idx) => {
      return (
        <SelectCheckItem
          id={`statusCheck${idx}`}
          title={item}
          key={`statusCheck${idx}`}
          checked={this.state.filters.status === idx}
          onChange={async () => await this.setFilter('status', idx)}
        />
      )
    })

    const tagsMenu = (
      <Menu>
        {this.state.tags.map((tag, idx) => (
          <SelectCheckItem
            id={`tagSelect${idx}`}
            key={`tagSelect${idx}`}
            checked={this.state.filters.tagsIds.includes(tag.id)}
            title={tag.text}
            onChange={async e => {
              let tags = this.state.filters.tagsIds
              if (e) {
                tags.push(tag.id)
              } else {
                tags = tags.filter(c => tag.id !== c)
              }
              this.setFilter('tagsIds', tags)
            }}
          />
        ))}
      </Menu>
    )
    let template = this.state.template ? this.state.templates.find(t => t.id === this.state.template) : null
    let templateTooltip = 'Select Template'
    if (template) {
      templateTooltip = template.text
    }

    return (
      <div className={styles.customers}>
        <Modal
          width={800}
          visible={this.state.showTemplateModal}
          onOk={() => this.setState({ showTemplateModal: false })}
          onCancel={() => this.setState({ showTemplateModal: false })}
        >
          <div className={styles.label}>Choose template</div>
          <div className={styles.templatesModal}>
            <Radio.Group onChange={e => this.setState({ template: e.target.value })}>{templates}</Radio.Group>
          </div>
        </Modal>
        <Modal
          visible={this.state.showSendCode}
          onOk={() => (this.state.phoneHash ? this.authorizeTelegram() : this.sendVerificationCode())}
          onCancel={() => this.setState({ phoneNumber: null, showSendCode: false })}
        >
          <div className={styles.verificationCodeLabel}> {this.state.hash ? 'Code:' : 'Phone number:'} </div>
          {this.state.phoneHash ? (
            <Input placeholder="Code" value={this.state.code} onChange={e => this.setState({ code: e.target.value })} />
          ) : (
            <Input
              placeholder="Phone Number"
              value={this.state.phoneNumber}
              onChange={e => this.setState({ phoneNumber: e.target.value })}
            />
          )}
        </Modal>
        <div className={styles.content}>
          <div className={styles.sortBlock}>
            <div className={styles.filters}>
              {!isTablet && <div className={styles.label}>{i18n.t('customers.filters')}</div>}
              <div className={styles.sortLine}>
                {isTablet && (
                  <div>
                    <AddButton
                      onChange={() => this.setState({ showNewMenu: !this.state.showNewMenu })}
                      showNewMenu={this.state.showNewMenu}
                      redirect={(location, title) => {
                        this.setState({ showNewMenu: false, currentMenu: title })
                        history.push(location)
                      }}
                    />
                  </div>
                )}
                <div className={styles.rightLine}>
                  {isTablet && <span className={styles.textSort}>{i18n.t('customers.filters')}</span>}
                  <DatePicker
                    className={styles.datePicker}
                    format={'DD/MM'}
                    placeholder="Birthday"
                    onChange={value => this.setFilter('birthDayDate', value)}
                  />
                  <Dropdown className={styles.dropDown} trigger={['click']} overlay={ageMenu}>
                    <Button className={styles.select}>{i18n.t('customers.age_range')}</Button>
                  </Dropdown>
                  <Dropdown trigger={['click']} overlay={genderMenu} className={styles.dropDown}>
                    <Button className={styles.select}>{i18n.t('customers.gender')}</Button>
                  </Dropdown>
                  <Dropdown trigger={['click']} overlay={citiesOpt} placement="bottomLeft" className={styles.dropDown}>
                    <Input
                      className={styles.select}
                      placeholder="Cities"
                      onChange={e => this.fetchCity(e.target.value)}
                    />
                  </Dropdown>
                  <Dropdown trigger={['click']} overlay={<Menu>{statusMenu}</Menu>} placeholder="Status">
                    <Button className={styles.select}>{i18n.t('customers.status')}</Button>
                  </Dropdown>
                  <Dropdown
                    overlayStyle={{ maxHeight: '500px', overflowY: 'scroll' }}
                    className={styles.dropDown}
                    trigger={['click']}
                    placement="bottomLeft"
                    overlay={tagsMenu}
                  >
                    <Button className={styles.select}>Tags</Button>
                    {/* <Input
                      className={styles.select}
                      placeholder="Tags"
                      onChange={e => this.fetchTag(e.target.value)}
                    /> */}
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className={styles.campaign}>
              <div className={styles.rightLine}>
                <span className={styles.label}>{i18n.t('campaigns.campaign')}</span>
                <div className={styles.sortLine}>
                  <Select
                    className={styles.selectCampaign}
                    onChange={v => this.setState({ campaign: v })}
                    value={this.state.campaign}
                    placeholder={i18n.t('customers.select_campaign')}
                  >
                    {campaigns}
                  </Select>
                  <Tooltip title={templateTooltip}>
                    <Button className={styles.sendButton} onClick={() => this.setState({ showTemplateModal: true })}>
                      {this.state.template ? 'Change template' : 'Select template'}
                    </Button>
                  </Tooltip>
                  {/* <Select
                    optionLabelProp="title"
                    className={styles.selectCampaign}
                    onChange={v => this.setState({ template: v })}
                    value={this.state.template}
                    placeholder={i18n.t('customers.select_template')}
                  >
                    {templates}
                  </Select> */}
                  <Button
                    disabled={
                      this.state.sendingCampaign || !this.state.campaign || !this.state.selectedCustomers.length
                    }
                    onClick={() => this.sendMessage()}
                    className={styles.sendButton}
                  >
                    {this.state.sendingCampaign ? <Icon type="loading" /> : i18n.t('customers.send_to_customer')}
                  </Button>
                  <Dropdown
                    overlay={invitationMenu}
                    disabled={this.state.sendingInvitation || !this.state.selectedCustomers.length}
                  >
                    <Button
                      // onClick={() => }
                      className={styles.sendButton}
                    >
                      {this.state.sendingCampaign ? <Icon type="loading" /> : `INVITE TO...`}{' '}
                      {!this.state.sendingCampaign && <Icon type="down" />}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <Table
            rowKey="id"
            virtualized={{
              renderNumber: 2,
              redundantNumber: 2,
              bodyHeight: '3.9em',
            }}
            rowSelection={rowSelection}
            className={styles.table}
            // pagination={false}
            dataSource={this.state.customers}
            columns={this.columns}
            loading={this.state.loading}
            title={() => (
              <div className={styles.paginationBlock}>
                <div className={styles.paginationLabel}>Rows per page</div>
                <Select
                  onChange={async value => {
                    this.changePageLength(value)
                  }}
                  value={this.state.filters.pageLength}
                  className={styles.selectPagination}
                >
                  {['10', '50', '100', '500'].map(val => (
                    <Select.Option key={val} value={val}>
                      {val}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  className={styles.sendCodeButton}
                  onClick={() => this.setState({ client: {}, showSendCode: true })}
                >
                  Send verification code
                </Button>
              </div>
            )}
            pagination={{
              pageSize: this.state.filters.pageLength,
              total: this.state.totalRecords,
              current: this.state.filters.pageNumber,
            }}
            onChange={this.handleTableChange}
          />
        </div>
      </div>
    )
  }
}

export default connect(state => ({ client: state.client }))(Customers)
