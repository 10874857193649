import React, { Component } from 'react'
import { Button, Table, Select, Input, message, Modal } from 'antd'

import setPageProps from 'actions/pageProps'
import styles from './styles.module.scss'
import * as accountActions from 'actions/account'
import * as clientActions from 'actions/client'

class Users extends Component {
  state = {
    client: null,
    accounts: [],
    loading: true,
    account: {},
    clients: [],
    editMode: false,
    accountFields: ['email', 'password', 'confirmPassword', 'phoneNumber', 'clientId'],
  }

  columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (record, data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Last Login date',
      dataIndex: 'lastLoginDate',
      key: 'lastLoginDate',
    },
    {
      render: (record, data) => (
        <div>
          <Button
            onClick={() =>
              this.setState({
                account: { ...data, clientId: data.client ? data.client.id : null },
                editAccount: true,
                editMode: true,
              })
            }
          >
            Edit
          </Button>
          <Button>Block</Button>
        </div>
      ),
    },
  ]
  componentDidMount = async () => {
    setPageProps({ title: 'List of users' })
    const client = this.props.location.state ? this.props.location.state.client : null
    const clients = await clientActions.getClients()
    this.setState(
      { client: client, clients: clients, account: { clientId: client ? client.id : null } },
      async () => await this.getAccounts()
    )
  }

  createAccount = async () => {
    let messageStr = 'fields are empty'
    try {
      let account = this.state.account
      let isValid = true

      this.state.accountFields.forEach(key => {
        const element = account[key]
        if (key === 'password') {
          isValid = element && element.length >= 6 && element === account.confirmPassword
          if (!element || element.length < 6) {
            messageStr = 'Password should be at least 6 characters long'
          }
        } else {
          isValid = isValid && element && element.length > 0
        }
      })
      // debugger
      if (isValid) {
        await accountActions.register(account)
        this.setState({ editAccount: false, editMode: false })
        await this.getAccounts()
      } else {
        message.error(messageStr)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      // this.setState({ editAccount: false, editMode: false })
    }
  }

  getAccounts = async () => {
    try {
      this.setState({ loading: true })
      const accounts = await accountActions.getAllUsers(this.state.client ? this.state.client.id : null)
      this.setState({ accounts: accounts.records || [], loading: false })
    } catch (error) {
      message.error(error.message)
    }
  }

  render() {
    const clients = this.state.clients.map(client => <Select.Option key={client.id}>{client.name}</Select.Option>)
    return (
      <div className={styles.clients}>
        <Modal
          visible={this.state.editAccount}
          onOk={() => this.createAccount()}
          onCancel={() => this.setState({ editAccount: false, editMode: false })}
        >
          {!this.state.editMode && (
            <div>
              <div className={styles.label}>email:</div>
              <Input
                placeholder="email"
                value={this.state.account.email}
                onChange={e => this.setState({ account: { ...this.state.account, email: e.target.value } })}
              />
              <div className={styles.label}>Password:</div>
              <Input
                placeholder="password"
                type="password"
                value={this.state.account.password}
                onChange={e => this.setState({ account: { ...this.state.account, password: e.target.value } })}
              />
              <div className={styles.label}>Confirm Password:</div>
              <Input
                placeholder="confirm password"
                type="password"
                value={this.state.account.confirmPassword}
                onChange={e => this.setState({ account: { ...this.state.account, confirmPassword: e.target.value } })}
              />
            </div>
          )}
          <div className={styles.label}> Phone Number:</div>

          <Input
            placeholder="phone number"
            value={this.state.account.phoneNumber}
            onChange={e => this.setState({ account: { ...this.state.account, phoneNumber: e.target.value } })}
          />
          <div className={styles.label}>Client:</div>
          <Select
            className={styles.select}
            value={this.state.account && this.state.account.clientId ? this.state.account.clientId : null}
            onChange={e => this.setState({ account: { ...this.state.account, clientId: e } })}
          >
            {clients}
          </Select>
        </Modal>

        <Button
          className={styles.addUser}
          onClick={() =>
            this.setState({
              account: {
                clientId: this.state.client
                  ? this.state.client.id
                  : this.state.clients.length
                  ? this.state.clients[0].id
                  : null,
              },
              editAccount: true,
            })
          }
        >
          Add User
        </Button>
        <Table rowKey="id" columns={this.columns} dataSource={this.state.accounts} loading={this.state.loading} />
      </div>
    )
  }
}

export default Users
