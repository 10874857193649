export const actionTypes = {
  SET: `TAGS_SET`,
}

const initState = {}

export default function clientReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.SET:
      return [...action.payload]
    default:
      return state
  }
}
