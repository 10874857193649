import { getAuthApi } from 'config'
import queryString from 'query-string'

export async function sendMessage(messageId) {
  let res = await getAuthApi().get(`/Telegram/bot/sendMessage?messageId=${messageId}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function sendMessageList(messageId, customersIds) {
  let res = await getAuthApi().post(`/Telegram/bot/sendMessage/list?messageId=${messageId}`, customersIds)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function unsubscribe(customerIds) {
  let res = await getAuthApi().get(`/Telegram/bot/unsubscribe?${queryString.stringify({ customerIds: customerIds })}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function sendVerificationCode(phoneNumber) {
  let res = await getAuthApi().get(`/Telegram/sendVerificationCode?phoneNumber=${phoneNumber}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function authorize(params) {
  let res = await getAuthApi().get(`/Telegram/authorize?${queryString.stringify(params)}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function sendInviteForUser(clientId, phoneNumber) {
  // console.log(`/Telegram/sendInviteForUser?${queryString.stringify({ clientId: clientId, phoneNumber: phoneNumber })}`)
  // return
  let res = await getAuthApi().get(
    `/Telegram/sendInviteForUser?${queryString.stringify({ clientId: clientId, phoneNumber: phoneNumber })}`
  )
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
