import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { Layout } from 'antd'

import Login from './Login'

import styles from './styles.module.scss'

export default function Public(props) {
  return (
    <Layout className={styles.layout}>
      <Layout.Content className={styles.content}>
        <Switch>
          <Route path="/login" component={Login} />
          <Redirect from="*" to="/login" />
        </Switch>
      </Layout.Content>
    </Layout>
  )
}
