import { getAuthApi } from 'config'
import store from '../store'
import { actionTypes as clientActionTypes } from '../store/reducers/client'

export async function getClients(filters) {
  let res = await getAuthApi().get(`/Client`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  // const client = res.data.records && res.data.records.length ? res.data.records[0] : {}
  // store.dispatch({ type: clientActionTypes.SET, payload: client })
  return res.data.records
}

export async function getClientByUserId() {
  let res = await getAuthApi().get(`/Client/getClientByUserId`)
  // debugger
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  store.dispatch({ type: clientActionTypes.SET, payload: res.data })
  return res.data
}

export async function saveClient(client) {
  let res,
    code = 201
  if (client.id) {
    code = 200
    const id = client.id
    delete client.id
    res = await getAuthApi().put(`/Client/${id}`, client)
  } else {
    res = await getAuthApi().post('/Client', client)
  }
  if (res.status !== code) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)

  store.dispatch({ type: clientActionTypes.SET, payload: res.data })
  return res.data
}
