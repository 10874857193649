import React from 'react'

import { Tag, Input, Tooltip, Icon } from 'antd'
import styles from './styles.module.scss'

class EditableTagGroup extends React.Component {
  state = {
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: '',
  }

  handleClose = removedTag => {
    const tags = this.props.tags.filter(tag => tag !== removedTag)
    this.props.onUpdateTags(tags)
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value })
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state
    let { tags } = this.props
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }
    this.setState({
      inputVisible: false,
      inputValue: '',
    })
    this.props.onUpdateTags(tags)
  }

  handleEditInputChange = e => {
    this.setState({ editInputValue: e.target.value })
  }

  handleEditInputConfirm = () => {
    const newTags = [...this.props.tags]
    newTags[this.state.editInputIndex] = this.state.editInputValue
    this.props.onUpdateTags(newTags)
    this.setState({
      editInputIndex: -1,
      editInputValue: '',
    })
  }

  saveInputRef = input => {
    this.input = input
  }

  saveEditInputRef = input => {
    this.editInput = input
  }

  render() {
    const { inputVisible, inputValue, editInputIndex, editInputValue } = this.state
    const { tags } = this.props
    return (
      <>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={this.saveEditInputRef}
                key={tag}
                size="small"
                className={styles.input}
                value={editInputValue}
                onChange={this.handleEditInputChange}
                onBlur={this.handleEditInputConfirm}
                onPressEnter={this.handleEditInputConfirm}
              />
            )
          }

          const isLongTag = tag.length > 20

          const tagElem = (
            <Tag className="edit-tag" key={tag} closable={true} onClose={() => this.handleClose(tag)}>
              <span
                onDoubleClick={e => {
                  if (index !== 0) {
                    this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                      this.editInput.focus()
                    })
                    e.preventDefault()
                  }
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          )
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          )
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            className={styles.input}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag className="site-tag-plus" onClick={this.showInput}>
            <Icon type="plus" /> New Tag
          </Tag>
        )}
      </>
    )
  }
}

export default EditableTagGroup
