import React, { Component } from 'react'
import { Layout } from 'antd'
import { isTablet } from 'react-device-detect'
import { Switch, Route, Redirect } from 'react-router'
import SideBar from 'components/SideBar'
import Customers from './Customers'
import Campaigns from './Campaigns'
import Campaign from './Campaigns/create'
import Customer from './Customers/create'

import withRouter from 'react-router/es/withRouter'
import styles from './styles.module.scss'
import Header from 'components/Header'
import Reports from './Reports'

class Manage extends Component {
  render = () => {
    return (
      <Layout>
        {isTablet ? null : <SideBar />}
        <Layout className={isTablet ? styles.tabletContent : styles.content}>
          <Header />
          <Layout className={styles.manageContent}>
            <Switch>
              <Route path="/manage/customers" component={Customers} />
              <Route path="/manage/campaigns" component={Campaigns} />
              <Route path="/manage/reports" component={Reports} />
              <Route path="/manage/campaign" component={Campaign} />
              <Route path="/manage/customer" component={Customer} />
              <Redirect from="*" to="/manage/customers" />
            </Switch>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

export default withRouter(Manage)
