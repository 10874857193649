import React, { Component } from 'react'
import { Input, Button, message } from 'antd'
import Checkbox from 'components/Checkbox'
import { connect } from 'react-redux'
import * as clientActions from 'actions/client'
import * as accountActions from 'actions/account'
import { ReactComponent as Logo } from 'sources/img/parooBlue.svg'

import history from 'utils/history'

import styles from './styles.module.scss'

class Login extends Component {
  state = {
    email: null,
    password: null,
    loading: false,
    rememberMe: true,
  }

  onClickSignIn = async e => {
    e.preventDefault()
    try {
      if (!this.state.email) {
        throw new Error('email is empty')
      }
      if (!this.state.password) {
        throw new Error('password is empty')
      }
      const account = await accountActions.login(this.state.email, this.state.password, this.state.rememberMe)

      if (account.role === 'Super Admin') {
        history.replace('/admin/clients')
      } else if (account.role === 'Admin') {
        await clientActions.getClientByUserId()
        history.replace('/manage/customers')
      }
      message.success(`Perfect! You signed in`, 3)
    } catch (error) {
      message.error(error.message, 3)
    }
  }

  render = () => {
    return (
      <div className={styles.login}>
        <div className={styles.form}>
          <div className={styles.centerImg}>
            <Logo width={230} />
          </div>
          <Input
            className={styles.input}
            size="large"
            placeholder="E-mail"
            onChange={e => this.setState({ email: e.target.value })}
            value={this.state.email}
          />
          <Input
            className={styles.input}
            size="large"
            placeholder="Password"
            type="password"
            onChange={e => this.setState({ password: e.target.value })}
            value={this.state.password}
          />
          <Button
            className={styles.button}
            type="primary"
            size="large"
            loading={this.state.loading}
            onClick={this.onClickSignIn}
            block
          >
            Sign In
          </Button>
          <div className={styles.links}>
            <Checkbox
              type="transparent"
              id="rememberMe"
              checked={this.state.rememberMe}
              // className={styles.checkbox}
              // type="checkbox"
              onChange={e => this.setState({ rememberMe: e })}
              label="Remember me"
            />
            <span>
              <button>Forgot your password?</button>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  account: state.account,
}))(Login)
