import { getAuthApi } from 'config'
import qs from 'qs'
import FileSaver from 'file-saver'

export async function getReports(filters) {
  if (filters && Object.keys(filters)) {
    Object.keys(filters).forEach(key => filters[key] == null && delete filters[key])
  }

  const params = filters && Object.keys(filters) ? '?'.concat(qs.stringify(filters)) : ''
  let res = await getAuthApi().get(`/Report${params}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  FileSaver.saveAs(new Blob([res.data]), `report-${new Date().getTime()}.csv`)
  return res.data
}
