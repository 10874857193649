import React, { Component } from 'react'
import { Button } from 'antd'
import styles from './styles.module.scss'

export default class AddButton extends Component {
  render() {
    return (
      <div className={styles.addButtonBlock}>
        <div>
          <Button
            onClick={() => this.props.onChange()}
            className={this.props.showNewMenu ? styles.addButtonInverted : styles.addButton}
          >
            Add
          </Button>
        </div>
        {this.props.showNewMenu && (
          <div className={styles.newButtonsInner}>
            <Button
              className={styles.newCustomer}
              onClick={() => this.props.redirect('customer', 'Create new customer')}
            >
              New customer
            </Button>
            <Button
              className={styles.newCampaign}
              onClick={() => this.props.redirect('campaign', 'Create new campaign')}
            >
              New campaign
            </Button>
          </div>
        )}
      </div>
    )
  }
}
