import React, { Component } from 'react'
import { Input, Icon, Button, Upload, message, Menu, Dropdown } from 'antd'
import * as Paroo from 'sources/img/paroo-admin.png'
import { isTablet } from 'react-device-detect'
import * as accountActions from 'actions/account'
import * as tagsActions from 'actions/tags'
import * as filesActions from 'actions/file'
import history from 'utils/history'
import styles from './styles.module.scss'
import { connect } from 'react-redux'

class Header extends Component {
  state = {
    showNewMenu: false,
  }

  exportClients = async () => {
    try {
      if (this.props.client) {
        await filesActions.exportData(this.props.client.id)
        message.success('Clients were exported')
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  render() {
    const importMenu = (
      <Menu onClick={this.import}>
        <Menu.Item key="1" className={styles.menuItem}>
          <Upload
            customRequest={() => {}}
            fileList={null}
            className={styles.upload}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            beforeUpload={async file => {
              try {
                if (!this.props.client || !this.props.client.id) {
                  return
                }
                const formData = new FormData()
                formData.append('file', file)
                await filesActions.importData(this.props.client.id, formData)
                message.success('File imported')
                await this.props.pageProps.refresh()
              } catch (e) {
                message.error(e.message)
              }
            }}
          >
            <div
              style={{
                width: '100%',
                color: '#222222',
                letterSpacing: '1px',
                fontSize: '1.5rem',
                fontFamily: 'SFProDisplay',
              }}
            >
              Customers
            </div>
          </Upload>
        </Menu.Item>
        <Menu.Item key="2" className={styles.menuItem}>
          <Upload
            customRequest={() => {}}
            fileList={null}
            // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            accept=".xls,.xlsx"
            beforeUpload={async file => {
              try {
                if (!this.props.client || !this.props.client.id) {
                  return
                }
                const formData = new FormData()
                formData.append('file', file)
                await tagsActions.importTags(this.props.client.id, formData)
                message.success('File imported')
                await this.props.pageProps.refresh()
              } catch (e) {
                message.error(e.message)
              }
            }}
          >
            <div
              style={{
                width: '100%',
                color: '#222222',
                letterSpacing: '1px',
                fontSize: '1.5rem',
                fontFamily: 'SFProDisplay',
              }}
            >
              Tags
            </div>
          </Upload>
        </Menu.Item>
      </Menu>
    )

    return (
      <div className={styles.header}>
        {isTablet && (
          <div className={styles.logoTopBlock}>
            <div className={styles.burger}>
              <Icon type="menu" onClick={() => this.setState({ showNewMenu: !this.state.showNewMenu })} />
              {this.state.showNewMenu && (
                <div className={styles.newButtonsInner}>
                  <Button
                    className={styles.newCustomer}
                    onClick={() => {
                      this.setState({ showNewMenu: false })
                      history.push('customers')
                    }}
                  >
                    List of customers
                  </Button>
                  <Button
                    className={styles.newCampaign}
                    onClick={() => {
                      this.setState({ showNewMenu: false })
                      history.push('campaigns')
                    }}
                  >
                    List of campaigns
                  </Button>
                  <Button className={styles.newCampaign} onClick={() => accountActions.logout()}>
                    Log out
                  </Button>
                </div>
              )}
            </div>

            <div className={styles.logoBG}>
              <img alt="Paroo" src={Paroo} />
            </div>
            <div className={styles.topLine} />
          </div>
        )}
        <div className={styles.leftGroup}>
          <span className={styles.label}>{this.props.pageProps.title}</span>
          {this.props.pageProps.buttons && (
            <div className={styles.row}>
              <Dropdown overlay={importMenu} trigger={['click']}>
                <Button>
                  IMPORT <Icon type="down" />
                </Button>
              </Dropdown>
              <Button onClick={() => this.exportClients()}>EXPORT</Button>
              {/* {this.props.pageProps.sendInvitation && (
                <Button onClick={() => this.props.pageProps.sendInvitation()}>SEND INVITATION</Button>
              )} */}
            </div>
          )}
        </div>
        {this.props.pageProps.search && (
          <div className={styles.search}>
            <Input.Search
              className={styles.searchInput}
              onChange={async e => this.props.pageProps.search(e.target.value)}
            />
          </div>
        )}
      </div>
    )
  }
}

export default connect(state => ({ pageProps: state.pageProps, client: state.client }))(Header)
