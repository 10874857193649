import React, { Component } from 'react'
import { Button, Table, message } from 'antd'
import setPageProps from 'actions/pageProps'
import * as campaignsActions from 'actions/campaign'
import Pen from 'sources/img/pen.png'
import Arrow from 'sources/img/arrow.png'
import { isTablet } from 'react-device-detect'
import history from 'utils/history'
import { connect } from 'react-redux'

import styles from './styles.module.scss'
import AddButton from 'components/AddButton'

class Campaigns extends Component {
  state = {
    currentPage: 1,
    newFirst: true,
    campaigns: [],
    pageLength: 10,
    loading: true,
    showModal: false,
    search: null,
    selectedCampaigns: [],
  }
  columns = [
    // {
    //   title: '▢',
    //   key: 'checked',
    //   // dataIndex: 'checked',
    //   render: (text, record, index) => (
    //     <Checkbox
    //       type="blue"
    //       id={record.id}
    //       indeterminate={true}
    //       checkAll={false}
    //       checked={this.state.selectedCampaigns.includes(record.id)}
    //       onChange={() => this.selectCampaign(record.id)}
    //     />
    //   ),
    // },
    {
      title: 'Title',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      key: 'text',
      width: 412,
      dataIndex: 'text',
    },
    {
      title: 'Link',
      dataIndex: 'url',
      key: 'url',
      // className: styles.tableLink,
      render: record => {
        let link = record
        if (record && record.length && !(record.includes('http:') || record.includes('//'))) {
          link = `//${record}`
        }
        return (
          <a className={styles.tableLink} href={link} target="_blank" rel="noopener noreferrer">
            {/* <Tooltip title={record}>{record}</Tooltip> */}
            {record}
          </a>
        )
      },
    },
    {
      title: 'Picture',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (text, record, index) => (text ? <img width={128} height={90} src={text} alt={text} /> : null),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => <span>{text === 0 ? 'New' : text === 1 ? 'Sent' : 'Archived'}</span>,
    },
    {
      title: '',
      key: '',
      width: 317,
      className: styles.columnButtons,
      render: (record, data) => (
        <div className={styles.buttons}>
          <Button onClick={() => history.push('campaign', { campaign: data })}>
            {isTablet ? <img src={Pen} alt="edit" /> : 'EDIT'}
          </Button>
          <Button onClick={() => history.push('customers', { campaign: data.id })}>
            {isTablet ? <img src={Arrow} alt="send" /> : 'SEND'}
          </Button>
        </div>
      ),
    },
  ]

  // sendMessage = async messageId => {
  //   try {
  //     await telegramActions.sendMessage(messageId)
  //     message.success('Campaign send')
  //   } catch (error) {
  //     message.error(error.message)
  //   }
  // }

  selectCampaign = id => {
    let { selectedCampaigns } = this.state
    if (selectedCampaigns.includes(id)) {
      selectedCampaigns = selectedCampaigns.filter(c => c.id === id)
    } else {
      selectedCampaigns.push(id)
    }
    this.setState({ selectedCampaigns: selectedCampaigns })
  }

  getCampaigns = async () => {
    this.setState({ loading: true })

    try {
      let params = {
        pageNumber: this.state.currentPage,
        pageLength: this.state.pageLength,
        newFirst: !this.state.newFirst,
      }
      if (this.props.client) {
        params.clientId = this.props.client.id
      }
      if (this.state.search) {
        params.search = this.state.search
      }
      let campaigns = await campaignsActions.getCampaigns(params)
      this.setState({ campaigns: campaigns.records, totalRecords: campaigns.pageInfo.totalRecords, loading: false })
    } catch (e) {
      message.error(e.message)
    } finally {
      this.setState({ loading: false })
    }
  }

  componentDidMount = async () => {
    setPageProps({
      title: 'List of campaigns',
      search: async v => this.setState({ search: v }, async () => await this.getCampaigns()),
    })
    try {
      await this.getCampaigns()
    } catch (error) {
      message.error(error.message)
    }
  }

  setPage = async page => {
    this.setState({ currentPage: page }, async () => await this.getCampaigns())
  }

  reorder = async newFirst => {
    this.setState({ newFirst: newFirst }, async () => await this.getCampaigns())
  }

  archiveCampaigns = async () => {
    try {
      await campaignsActions.archiveCampaigns(this.state.selectedCampaigns)
      message.success(`Archived ${this.state.selectedCampaigns.length} campaigns`)
      this.setState({ selectedCampaigns: [] })

      await this.getCampaigns()
    } catch (error) {
      message.error(error.message)
    }
  }

  render() {
    const selectedRowKeys = this.state.selectedCampaigns
    const rowSelection = {
      selectedRowKeys,
      onSelectAll: row => {
        this.setState({ selectedAllCampaigns: row })
      },
      onChange: row => {
        this.setState({ selectedCampaigns: row, selectedAllCampaigns: row.length === this.state.campaigns.length })
      },
    }

    return (
      <div className={styles.campaigns}>
        <div className={styles.content}>
          <div className={styles.sortBlock}>
            <div className={styles.filters}>
              {isTablet && <AddButton />}
              <Button
                className={styles.yellowButton}
                disabled={!this.state.selectedCampaigns.length}
                onClick={() => this.archiveCampaigns()}
              >
                Archive selected
              </Button>
            </div>
            <div className={styles.campaign}>
              <div className={styles.label}>
                <span className={styles.sortByDate}>Sort by date</span>
                <Button
                  className={this.state.newFirst ? styles.sortButtonActive : styles.sortButton}
                  onClick={() => this.reorder(true)}
                >
                  New first
                </Button>
                <Button
                  className={!this.state.newFirst ? styles.sortButtonActive : styles.sortButton}
                  onClick={() => this.reorder(false)}
                >
                  Old first
                </Button>
              </div>
            </div>
          </div>
          <Table
            rowSelection={rowSelection}
            className={styles.table}
            loading={this.state.loading}
            // pagination={false}
            dataSource={this.state.campaigns}
            columns={this.columns}
            rowKey="id"
            pagination={{
              pageSize: this.state.pageLength,
              total: this.state.totalRecords,
              current: this.state.currentPage,
            }}
            onChange={current => this.setPage(current.current)}
          />
          <div className={styles.paginationWrapper}>
            <Button className={styles.yellowButton} onClick={() => history.push('campaign')}>
              Add New
            </Button>
          </div>
          {/* 
            <div className={styles.pagination}>
              <Pagination
                pageNumber={this.state.currentPage}
                pageLength={this.state.pageLength}
                total={this.state.totalRecords}
                onChange={}
              />
            </div>
            <div className={styles.addNewContainer}>
              
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}

export default connect(state => ({ client: state.client }))(Campaigns)
