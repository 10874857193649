import { combineReducers } from 'redux'

import account from './account'
import pageProps from './pageProps'
import client from './client'
import tags from './tags'

export default combineReducers({
  account,
  pageProps,
  client,
  tags,
})
