import axios from 'axios'
import store from './store'
// import { actionTypes as accountActionTypes } from 'store/reducers/account'
import { unsetAccount } from 'actions/account'

export const api = axios.create({
  baseURL: process.env.REACT_APP_HOST,
  validateStatus: function(status) {
    return true
  },
})

export function getAuthApi() {
  const account = store.getState().account
  if (!account) throw new Error(`Unauthorized`)
  const req = axios.create({
    baseURL: process.env.REACT_APP_HOST,
    headers: { Authorization: `Bearer ${account.accessToken}` },
    validateStatus: () => {
      return true
    },
  })
  req.interceptors.response.use(
    response => {
      if (response.status === 401) {
        unsetAccount(true)
      }
      // Do something with response data
      return response
    },
    error => {
      // console.log(error.response)
      // Do something with response error
      return Promise.reject(error)
    }
  )

  return req
}

export const EVENT_TYPES = [
  {
    code: 0,
    title: 'Link Click',
  },
  {
    code: 1,
    title: 'Like',
  },
  {
    code: 2,
    title: 'Dislike',
  },
  {
    code: 3,
    title: 'Send Message',
  },
  {
    code: 4,
    title: 'Send Invitation',
  },
]

export const CUSTOMER_STATUSES = {
  0: 'Subscribed',
  1: 'Unsubscribed',
  2: 'New',
  3: 'Invitation Sent',
}
