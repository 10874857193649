import { getAuthApi } from 'config'
import queryString from 'query-string'

export async function getCities(params) {
  if (!params.name) delete params.name
  let queryParams = params && Object.keys(params) ? '?'.concat(queryString.stringify(params)) : ''
  let res = await getAuthApi().get(`/City${queryParams}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)

  return res.data.records
}
