import React, { Component } from 'react'
import Checkbox from './index'
import styles from './styles.module.scss'

export default class SelectCheckItem extends Component {
  render() {
    return (
      <div className={styles.checkDropDown}>
        <div className={styles.check}>
          <Checkbox
            type="yellow"
            id={this.props.id}
            indeterminate={true}
            checkAll={false}
            onChange={e => this.props.onChange(e)}
            checked={this.props.checked}
          />
        </div>

        <span>{this.props.title}</span>
      </div>
    )
  }
}
