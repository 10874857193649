import React, { Component } from 'react'
import { Button, Table, Input, message, Modal } from 'antd'

import setPageProps from 'actions/pageProps'

import styles from './styles.module.scss'

import * as industriesAction from 'actions/industry'

class Industries extends Component {
  state = {
    industries: [],
    loading: true,
    industry: {},
    client: {},
  }

  columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (record, data, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },

    {
      render: (record, data) => (
        <div>
          <Button onClick={() => this.setState({ industry: data, editIndustry: true })}>Edit</Button>
        </div>
      ),
    },
  ]
  componentDidMount = async () => {
    setPageProps({ title: 'List of industries' })
    await this.getIndustries()
  }

  getIndustries = async () => {
    try {
      this.setState({ loading: true })
      const industries = await industriesAction.getIndustries()

      this.setState({ industries: industries, loading: false })
    } catch (error) {
      message.error(error.message)
    } finally {
      this.setState({ loading: false })
    }
  }

  saveIndustry = async () => {
    try {
      this.setState({ loading: true })
      let { industry } = this.state
      if (!industry.name) {
        message.error('No industry name')
      } else {
        await industriesAction.saveIndustry(industry)
        this.setState({ industry: {}, editIndustry: false })
        message.success('Industry was saved')
        await this.getIndustries()
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  render() {
    return (
      <div className={styles.industries}>
        <Modal
          visible={this.state.editIndustry}
          onOk={() => this.saveIndustry()}
          onCancel={() => this.setState({ industry: {}, editIndustry: false })}
        >
          <div className={styles.label}>Name:</div>
          <Input
            placeholder="name"
            value={this.state.industry.name}
            onChange={e => this.setState({ industry: { ...this.state.industry, name: e.target.value } })}
          />
        </Modal>
        <Button className={styles.addIndustry} onClick={() => this.setState({ industry: {}, editIndustry: true })}>
          Add Industry
        </Button>
        <Table rowKey="id" columns={this.columns} dataSource={this.state.industries} loading={this.state.loading} />
      </div>
    )
  }
}

export default Industries
