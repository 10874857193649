import { getAuthApi } from '../config'
import FileSaver from 'file-saver'

export async function uploadFile(formData) {
  let res = await getAuthApi().post('File', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  if (res.status !== 200) throw new Error(`Error uploading file`)

  return process.env.REACT_APP_HOST.concat('File/', res.data)
}

export async function exportData(clientId) {
  let res = await getAuthApi().get(`/Export?clientId=${clientId}`, { responseType: 'blob' })
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  FileSaver.saveAs(new Blob([res.data]), 'clients.xlsx')
  return res.data
}

export async function importData(clientId, file) {
  let res = await getAuthApi().post(`/Import?clientId=${clientId}`, file)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
