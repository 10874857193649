import { getAuthApi } from 'config'
import store from '../store'
import { actionTypes as tagsActionTypes } from '../store/reducers/tags'

export async function getAllTags(clientId) {
  let res = await getAuthApi().get(`/Tag?clientId=${clientId}`)
  store.dispatch({ type: tagsActionTypes.SET, payload: res.data.records })
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data.records
}

export async function importTags(clientId, file) {
  let res = await getAuthApi().post(`/Tag/import?clientId=${clientId}`, file)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function saveTag(tag) {
  let res = await getAuthApi().post(`/Tag`, tag)
  if (res.status !== 201) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
