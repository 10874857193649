import I18n from 'i18n-js'

import en from './locales/en.json'
import he from './locales/he.json'

let i18n = I18n
i18n.defaultLocale = 'en'
i18n.locale = 'en'
i18n.fallbacks = true
i18n.getEllipsizeMode = () => {
  return i18n.locale === 'en' ? 'tail' : 'head'
}

i18n.translations = { en, he }
export default i18n
