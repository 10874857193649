import { getAuthApi } from 'config'

export async function getIndustries() {
  let res = await getAuthApi().get(`/Industry`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data.records ? res.data.records : []
}

export async function saveIndustry(industry) {
  let res,
    code = 201
  if (industry.id) {
    code = 200
    const id = industry.id
    delete industry.id
    res = await getAuthApi().put(`/Industry/${id}`, industry)
  } else {
    res = await getAuthApi().post('/Industry', industry)
  }
  if (res.status !== code) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
