import React, { Component } from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import history from 'utils/history'
import * as accountActions from 'actions/account'
import AddButton from 'components/AddButton'
import i18n from 'sources/i18n'

import { ReactComponent as Paroo } from '../../sources/img/paroo.svg'

import styles from './styles.module.scss'

class SideBar extends Component {
  state = {
    showNewMenu: false,
    currentMenu: null,
  }
  render() {
    const selected = history.location.pathname

    return (
      <Layout.Sider className={styles.sider} width={315}>
        <div className={styles.logoTopBlock}>
          <div className={styles.logoBG}>
            <Paroo />
          </div>
        </div>
        <div className={styles.siderBG}>
          <Menu
            inlineIndent={30}
            onSelect={e => {
              this.setState({ selected: e.key })
            }}
            mode="inline"
            className={styles.menu}
          >
            <AddButton
              onChange={() => this.setState({ showNewMenu: !this.state.showNewMenu })}
              showNewMenu={this.state.showNewMenu}
              redirect={(location, title) => {
                this.setState({ showNewMenu: false, currentMenu: title })
                history.push(location)
              }}
            />
            {this.state.currentMenu && (
              <Menu.Item key="0" className={styles.menuItem}>
                <span className={styles.topicMenuItem}>{this.state.currentMenu}</span>
              </Menu.Item>
            )}

            <Menu.Item key="1" className={styles.menuItem}>
              <Link
                className={styles.menuItem}
                to="/manage/customers"
                onClick={() => {
                  this.setState({ showNewMenu: false, currentMenu: null })
                }}
              >
                <span className={selected === '/manage/customers' ? styles.menuSelected : styles.spanItem}>
                  {i18n.t('customers.list_of_customers')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2" className={styles.menuItem}>
              <Link
                className={styles.menuItem}
                to="/manage/campaigns"
                onClick={() => {
                  this.setState({ showNewMenu: false, currentMenu: null })
                }}
              >
                <span className={selected === '/manage/campaigns' ? styles.menuSelected : styles.spanItem}>
                  {i18n.t('campaigns.list_of_campaigns')}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3" className={styles.menuItem}>
              <Link
                className={styles.menuItem}
                to="/manage/reports"
                onClick={() => {
                  this.setState({ showNewMenu: false, currentMenu: null })
                }}
              >
                <span className={selected === '/manage/reports' ? styles.menuSelected : styles.spanItem}>Reports</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              key="4"
              className={styles.lastMenuItem}
              onClick={async () => {
                try {
                  await accountActions.logout()
                } catch (e) {
                  console.log(e)
                } finally {
                  history.push('/')
                }
              }}
            >
              <span className={styles.spanItem}>Log out</span>
            </Menu.Item>
          </Menu>
        </div>
      </Layout.Sider>
    )
  }
}

export default SideBar
