import React, { Component } from 'react'
import { Button, Table, Input, message, Modal, Select } from 'antd'

import setPageProps from 'actions/pageProps'

import styles from './styles.module.scss'

import { connect } from 'react-redux'
import * as clientActions from 'actions/client'
import * as industryActions from 'actions/industry'
import * as telegramActions from 'actions/telegram'
import history from 'utils/history'

class Clients extends Component {
  state = {
    clients: [],
    industries: [],
    showRegister: false,
    loading: true,
    client: {},
    clientFields: ['name', 'city', 'address', 'phoneNumber', 'industryId'],
    account: {
      clientId: null,
      email: null,
      password: null,
      confirmPassword: null,
      phoneNumber: null,
    },
  }

  columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'City',
      key: 'city',
      dataIndex: 'city',
    },
    {
      title: 'Address',
      key: 'address',
      dataIndex: 'address',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      render: (record, data) => (
        <div>
          <Button onClick={() => this.setState({ client: data, editClient: true })}>Edit</Button>
          <Button onClick={() => history.push('/admin/users', { client: data })}>Users list</Button>
        </div>
      ),
    },
  ]

  componentDidMount = async () => {
    setPageProps({ title: 'List of clients' })
    try {
      const industries = await industryActions.getIndustries()
      this.setState({ industries: industries })
    } catch (error) {}
    await this.getClients()
  }

  getClients = async () => {
    try {
      // debugger
      this.setState({ loading: true })
      const clients = await clientActions.getClients()
      this.setState({ clients: clients, loading: false })
    } catch (error) {
      message.error(error.message)
    }
  }

  saveClient = async () => {
    const stateClient = this.state.client
    let isValid = true
    this.state.clientFields.forEach(key => {
      const element = stateClient[key]
      isValid = element && element.length
    })
    if (!isValid) {
      message.error('Fields are empty')
      return
    }
    try {
      this.setState({ loading: true })
      await clientActions.saveClient(this.state.client)
      message.success('Client saved')
    } catch (error) {
      message.error(error.message)
    } finally {
      await this.getClients()
      this.setState({
        client: {},
        editClient: false,
        loading: false,
      })
    }
  }

  sendVerificationCode = async () => {
    try {
      let phoneNumber = this.state.phoneNumber ? this.state.phoneNumber.replace(/\D/g, '') : ''
      if (!phoneNumber || phoneNumber.length < 8) {
        message.error('Wrong phone number')
      } else {
        const hash = await telegramActions.sendVerificationCode(phoneNumber)
        if (!hash) {
          message.error('Error with getting verification code')
        } else {
          this.setState({ hash: hash })
        }
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  authorize = async () => {
    try {
      if (!this.state.code) {
        message.error('Wrong code')
      } else {
        await telegramActions.authorize({
          code: this.state.code,
          hash: this.state.hash,
          phoneNumber: this.state.phoneNumber,
        })
        message.success('Authorized')
        this.setState({ showSendCode: false, code: null, hash: null, phoneNumber: null })
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  render() {
    const industries = this.state.industries.map(industry => (
      <Select.Option key={industry.id}>{industry.name}</Select.Option>
    ))
    return (
      <div className={styles.clients}>
        <Modal
          visible={this.state.editClient}
          onOk={() => this.saveClient()}
          onCancel={() => this.setState({ client: {}, editClient: false })}
        >
          <div className={styles.label}>Name:</div>
          <Input
            placeholder="name"
            value={this.state.client.name}
            onChange={e => this.setState({ client: { ...this.state.client, name: e.target.value } })}
          />
          <div className={styles.label}>City:</div>

          <Input
            placeholder="city"
            value={this.state.client.city}
            onChange={e => this.setState({ client: { ...this.state.client, city: e.target.value } })}
          />
          <div className={styles.label}>Address:</div>

          <Input
            placeholder="address"
            value={this.state.client.address}
            onChange={e => this.setState({ client: { ...this.state.client, address: e.target.value } })}
          />
          <div className={styles.label}> Phone Number:</div>

          <Input
            placeholder="phone number"
            value={this.state.client.phoneNumber}
            onChange={e => this.setState({ client: { ...this.state.client, phoneNumber: e.target.value } })}
          />
          <div className={styles.label}> Email:</div>
          <Input
            placeholder="email"
            value={this.state.client.email}
            onChange={e => this.setState({ client: { ...this.state.client, email: e.target.value } })}
          />
          <div className={styles.label}> Industry:</div>
          <Select
            className={styles.select}
            value={this.state.client.industryId}
            onChange={e => this.setState({ client: { ...this.state.client, industryId: e } })}
          >
            {industries}
          </Select>
        </Modal>
        <Modal
          visible={this.state.showSendCode}
          onOk={() => (this.state.hash ? this.authorize() : this.sendVerificationCode())}
          onCancel={() => this.setState({ phoneNumber: null, showSendCode: false })}
        >
          <div className={styles.label}> {this.state.hash ? 'Code:' : 'Phone number:'} </div>
          {this.state.hash ? (
            <Input placeholder="Code" value={this.state.code} onChange={e => this.setState({ code: e.target.value })} />
          ) : (
            <Input
              placeholder="Phone Number"
              value={this.state.phoneNumber}
              onChange={e => this.setState({ phoneNumber: e.target.value })}
            />
          )}
        </Modal>
        <div>
          <Button className={styles.addClient} onClick={() => this.setState({ client: {}, editClient: true })}>
            Add Client
          </Button>
          <Button className={styles.addClient} onClick={() => this.setState({ client: {}, showSendCode: true })}>
            Send verification code
          </Button>
        </div>
        <Table rowKey="id" columns={this.columns} dataSource={this.state.clients} loading={this.state.loading} />
      </div>
    )
  }
}

export default connect(state => ({ client: state.client }))(Clients)
