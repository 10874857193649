import { getAuthApi } from 'config'

export async function saveCustomer(customer) {
  let res,
    code = 201
  if (customer && Object.keys(customer)) {
    Object.keys(customer).forEach(key => customer[key] == null && delete customer[key])
  }
  if (customer.gender && !isNaN(parseInt(customer.gender))) {
    customer.gender = parseInt(customer.gender)
  }
  if (customer.appartment && !isNaN(parseInt(customer.appartment))) {
    customer.appartment = parseInt(customer.appartment)
  }
  if (customer.houseNumber && !isNaN(parseInt(customer.houseNumber))) {
    customer.houseNumber = parseInt(customer.houseNumber)
  }
  if (customer.id) {
    const id = customer.id
    delete customer.id
    delete customer.age
    code = 200
    res = await getAuthApi().put(`/Customer/${id}`, customer)
  } else {
    res = await getAuthApi().post('/Customer', customer)
  }
  if (res.status !== code) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)

  return res.data
}

export async function changeStatus(id, status) {
  let res = await getAuthApi().post(`/Customer/changeStatus/${id}?status=${status}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function getCustomers(filters) {
  const { pageLength = 10, pageNumber = 1, ...requestBody } = filters
  let res = await getAuthApi().post(
    `/Customer/getCustomers?pageLength=${pageLength}&pageNumber=${pageNumber}`,
    requestBody
  )
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
