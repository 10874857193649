import React, { Component } from 'react'
import { Button, Select, DatePicker, Icon, message } from 'antd'

import setPageProps from 'actions/pageProps'
import { isTablet } from 'react-device-detect'
import AddButton from 'components/AddButton'

import i18n from 'sources/i18n'

import styles from './styles.module.scss'
import moment from 'moment'
import history from 'utils/history'
import * as clientActions from 'actions/client'
import * as campaignsActions from 'actions/campaign'
import * as reportsActions from 'actions/reports'
import { connect } from 'react-redux'
import { EVENT_TYPES } from 'config'

class Reports extends Component {
  state = {
    reports: [],
    campaigns: [],
    clients: [],
    totalRecords: 0,
    filters: {
      // pageLength: 10,
      // pageNumber: 1,
      startDate: null,
      endDate: null,
      messageId: null,
      clientId: this.props.account && this.props.account.role !== 'Super Admin' ? this.props.client.id : null,
      eventType: null,
    },
  }
  // columns = [
  //   {
  //     title: 'Date / Time',
  //   },
  //   {
  //     title: 'Event Type',
  //   },
  //   {
  //     title: 'Campaign Name',
  //   },
  //   {
  //     title: 'Customer Name',
  //   },
  // ]
  componentDidMount = async () => {
    setPageProps({
      title: 'Reports',
      buttons: false,
      //   refresh: async () => await this.getCustomers(),
      //   search: async v => this.setFilter('search', v),
    })
    try {
      let clientId = this.props.client.id
      // await this.getReports()
      // const locationParams = this.props.location.state
      const clients = await clientActions.getClients()
      // if (locationParams && locationParams.clientId) {
      //   clientId = locationParams.clientId
      // }
      const campaigns = await campaignsActions.getCampaigns({
        newFirst: true,
        clientId: clientId,
      })
      this.setState({ campaigns: campaigns.records, clients: clients })
    } catch (error) {}
  }

  getReports = async () => {
    try {
      reportsActions.getReports(this.state.filters)
      message.success('Report was saved')
    } catch (error) {
      message.error(error.message)
    }
  }

  setFilter = async (name, value) => {
    let { filters } = this.state

    if (name === 'status') {
      const status = this.state.statusFilter
      filters[name] = (status[0] && status[1]) || (!status[0] && !status[1]) ? null : status[0] ? 0 : 1
    } else if (name === 'startDate' || name === 'endDate') {
      let date = value ? moment(value) : null
      if (date && name === 'startDate') {
        filters[name] = date
          .hours('0')
          .minutes('00')
          .seconds('00')
          .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
      }
      if (date && name === 'endDate') {
        filters[name] = date
          .hours('23')
          .minutes('59')
          .seconds('59')
          .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
      }
    } else {
      filters[name] = value
    }
    this.setState({ filters: filters })
    // await this.getReports(this.state.clientId)
  }

  render() {
    const campaigns = this.state.campaigns.map(campaign => (
      <Select.Option key={campaign.id} title={campaign.name} value={campaign.id}>
        {campaign.name}
      </Select.Option>
    ))
    const { account } = this.props
    const clientsOpts = this.state.clients.map(client => (
      <Select.Option key={client.id} title={client.name} value={client.id}>
        {client.name}
      </Select.Option>
    ))

    const eventTypeOpts = EVENT_TYPES.map(event => (
      <Select.Option key={event.code} title={event.title}>
        {event.title}
      </Select.Option>
    ))

    return (
      <div className={styles.reports}>
        <div className={styles.content}>
          <div className={styles.sortBlock}>
            <div className={styles.filters}>
              {!isTablet && <div className={styles.label}>{i18n.t('customers.filters')}</div>}
              <div className={styles.sortLine}>
                {isTablet && (
                  <div>
                    <AddButton
                      onChange={() => this.setState({ showNewMenu: !this.state.showNewMenu })}
                      showNewMenu={this.state.showNewMenu}
                      redirect={(location, title) => {
                        this.setState({ showNewMenu: false, currentMenu: title })
                        history.push(location)
                      }}
                    />
                  </div>
                )}
                <div className={styles.rightLine}>
                  {isTablet && <span className={styles.textSort}>{i18n.t('customers.filters')}</span>}
                  <DatePicker
                    className={styles.datePicker}
                    format={'DD/MM/YYYY'}
                    placeholder="Date From"
                    onChange={value => this.setFilter('startDate', value)}
                  />
                  <DatePicker
                    className={styles.datePicker}
                    format={'DD/MM/YYYY'}
                    placeholder="Date To"
                    onChange={value => this.setFilter('endDate', value)}
                  />
                  <span className={styles.label}>Event type: </span>
                  <Select className={styles.select} onChange={e => this.setFilter('eventType', e)}>
                    <Select.Option value={null}>Any</Select.Option>
                    {eventTypeOpts}
                  </Select>
                  {account && account.role === 'Super Admin' && (
                    <>
                      <span className={styles.labelClient}>Client: </span>
                      <Select className={styles.select} onChange={e => this.setFilter('clientId', e)}>
                        <Select.Option value={null}>Any</Select.Option>
                        {clientsOpts}
                      </Select>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.campaign}>
              <div className={styles.rightLine}>
                <div className={styles.sortLine}>
                  <Select
                    className={styles.select}
                    onChange={v => this.setFilter('messageId', v)}
                    placeholder="Select campaign"
                  >
                    <Select.Option value={null}>Any</Select.Option>
                    {campaigns}
                  </Select>
                  <Button onClick={() => this.getReports()} className={styles.sendButton}>
                    {this.state.sendingCampaign ? <Icon type="loading" /> : 'Get report'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {/* <Table
            rowKey="id"
            virtualized={{
              renderNumber: 2,
              redundantNumber: 2,
              bodyHeight: '3.9em',
            }}
            // rowSelection={rowSelection}
            className={styles.table}
            pagination={false}
            dataSource={this.state.reports}
            columns={this.columns}
            loading={this.state.loading}
          />
          <div className={styles.paginationWrapper}>
            <Pagination
              className={styles.pagination}
              pageNumber={this.state.filters.pageNumber}
              pageLength={this.state.filters.pageLength}
              total={this.state.totalRecords}
              onChange={e => this.setFilter('pageNumber', e)}
            />
          </div> */}
        </div>
      </div>
    )
  }
}

export default connect(state => ({ client: state.client, account: state.account }))(Reports)
