import React, { Component } from 'react'
import { Input, Button, Popconfirm, message, Table, Modal } from 'antd'

import * as templatesActions from 'actions/templates'
// import * as clientActions from 'actions/client'
import setPageProps from 'actions/pageProps'

import styles from './styles.module.scss'

class Templates extends Component {
  state = {
    templates: [],
    template: {},
    editTemplate: false,
  }

  columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Text',
      key: 'text',
      dataIndex: 'text',
      render: (record, data) => {
        const lines = record ? record.split('\n') : []
        return (
          <div>
            {lines.map(line => (
              <div>{line}</div>
            ))}
          </div>
        )
      },
    },
    {
      render: (record, data) => (
        <div className={styles.controlButtons}>
          <Button onClick={() => this.setState({ template: data, editTemplate: true })}>Edit</Button>
          <Popconfirm
            title="Are you sure delete this task?"
            onConfirm={() => this.deleteTemplate(data.id)}
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
            className={styles.deleteButton}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </div>
      ),
    },
  ]

  componentDidMount = async () => {
    setPageProps({ title: 'List of templates' })
    await this.getTemplates()
  }

  getTemplates = async (pageNumber = 1, pageCount = 10) => {
    try {
      this.setState({ loading: true })
      const templates = await templatesActions.getTemplates()
      this.setState({ templates: templates, loading: false })
    } catch (error) {
      message.error(error.message)
    }
  }

  deleteTemplate = async id => {
    try {
      await templatesActions.deleteTemplate(id)
      await this.getTemplates()
    } catch (error) {
      message.error(error.message)
    }
  }

  saveTemplate = async () => {
    const { template } = this.state

    if (!template.text) {
      message.error('Text is empty')
      return
    }
    try {
      this.setState({ loading: true })
      if (template.id) {
        await templatesActions.updateTemplate(template)
      } else {
        await templatesActions.createTemplate(template.text)
      }

      message.success('Template saved')
      await this.getTemplates()
    } catch (error) {
      message.error(error.message)
    } finally {
      this.setState({
        template: {},
        editTemplate: false,
        loading: false,
      })
    }
  }

  render() {
    return (
      <div className={styles.templates}>
        <Modal
          visible={this.state.editTemplate}
          onOk={() => this.saveTemplate()}
          onCancel={() => this.setState({ template: {}, editTemplate: false })}
        >
          <div className={styles.label}>Text:</div>
          <Input.TextArea
            placeholder="Text"
            value={this.state.template.text}
            className={styles.input}
            onChange={e => this.setState({ template: { ...this.state.template, text: e.target.value } })}
          />
        </Modal>
        <Button className={styles.createButton} onClick={() => this.setState({ template: {}, editTemplate: true })}>
          Create template
        </Button>
        <Table rowKey="id" columns={this.columns} dataSource={this.state.templates} loading={this.state.loading} />
      </div>
    )
  }
}

export default Templates
