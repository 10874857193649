import { getAuthApi } from 'config'
// import queryString from 'query-string'

export async function sendInvitations(clientId, customersIds) {
  const customerId = customersIds[0] || ''
  let res = await getAuthApi().post(`/WhatsApp?clientId=${clientId}&customerId=${customerId}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function sendMessageTemplate(clientId, customerId, template) {
  let res = await getAuthApi().post(
    `/WhatsApp/sendMessageTemplate?clientId=${clientId}&customerId=${customerId}&template=${template}`
  )
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function sendMessageTemplateList(params) {
  // const params = filters && Object.keys(filters) ? queryString.stringify(filters) : ''
  let res = await getAuthApi().post(`/WhatsApp/sendMessageTemplateList`, params)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function getSendMessageState(id) {
  let res = await getAuthApi().get(`/WhatsApp/getSendMessageState?id=${id}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
