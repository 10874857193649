export const actionTypes = {
  SET: `PAGE_PROPS_SET`,
}

const initState = []

export default function pagePropsReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.SET:
      return { ...action.payload }
    default:
      return state
  }
}
