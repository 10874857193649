import { getAuthApi } from 'config'
import queryString from 'query-string'

export async function saveCampaign(campaign) {
  let res,
    code = 201
  if (campaign.id) {
    code = 200
    const id = campaign.id
    delete campaign.id

    res = await getAuthApi().put(`/Message/${id}`, campaign)
  } else {
    res = await getAuthApi().post('/Message', campaign)
  }
  if (res.status !== code) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function getCampaigns(filters) {
  const params = filters && Object.keys(filters) ? '?'.concat(queryString.stringify(filters)) : ''
  let res = await getAuthApi().get(`/Message${params}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function getRedirectUrl(url, clientId) {
  // console.log()
  // let res = await getAuthApi().get(`/Redirect?returnUrl=${encodeURIComponent(url)}&clientId=${clientId}`)
  let res = await getAuthApi().get(`/Redirect?returnUrl=${encodeURIComponent(url.concat(`&clientId=`, clientId))}`)
  // debugger
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}

export async function archiveCampaigns(campaignIds) {
  const params = campaignIds ? '?'.concat(queryString.stringify({ messagesForChangingStatuses: campaignIds })) : ''
  let res = await getAuthApi().patch(`/Message${params}`)
  if (res.status !== 200) throw new Error(res.data && res.data.Message ? res.data.Message : `Error`)
  return res.data
}
