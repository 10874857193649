import React, { Component } from 'react'
import { Input, Button, Select, DatePicker, message, Tooltip } from 'antd'
import TagsList from 'components/TagsList'
import * as customersActions from 'actions/customer'
import setPageProps from 'actions/pageProps'
import { isTablet } from 'react-device-detect'
import moment from 'moment'
import * as citiesActions from 'actions/city'
import * as tagsActions from 'actions/tags'
import countries from 'sources/json/countries.json'
import { connect } from 'react-redux'
import history from 'utils/history'

import styles from './styles.module.scss'

import { CUSTOMER_STATUSES } from 'config'

// const genders = ['Male', 'Female']

const baseCustomer = {
  firstName: null,
  lastName: null,
  country: null,
  city: null,
  gender: null,
  street: null,
  houseNumber: 0,
  appartment: 0,
  birthdayDate: null,
  email: null,
  phoneNumber: null,
}

class Customer extends Component {
  state = {
    customer: { ...baseCustomer, clientId: this.props.client.id },
    cities: [],
    tagLists: [],
    tags: [],
    dialCode: null,
    cityParams: {
      name: null,
      pageLength: 1,
    },
    fields: ['firstName', 'country', 'phoneNumber'],
    // submitTry: false,
    errors: {},
    customerStatus: 0,
  }

  saveTags = async () => {
    let tagIds = []
    const { tagLists, tags } = this.state
    for (let tag in tags) {
      try {
        const storedTag = tagLists.find(t => t.text === tags[tag])
        if (storedTag) {
          tagIds.push(storedTag.id)
        } else {
          const res = await tagsActions.saveTag({ clientId: this.props.client.id, text: tags[tag] })
          tagIds.push(res.id)
        }
      } catch (error) {
        console.log(error.message)
      }
    }
    return tagIds
  }

  submit = async () => {
    let errors = {}
    this.state.fields.forEach(key => {
      let value = this.state.customer[key]
      if (key === 'phoneNumber') {
        value = value ? value.replace(/\D/g, '') : ''
        if (!this.state.dialCode || !value || (value.length < 7 && value.length > 9)) {
          errors.phoneNumber = true
        }
      } else if (!value) {
        errors[key] = true
      }
    })
    this.setState({ errors: errors })
    try {
      if (!Object.keys(errors).length) {
        let customer = { ...this.state.customer }
        customer.tagsIds = await this.saveTags()
        delete customer.tags
        // let country = countries.find(country => country.name === customer.country)
        customer.phoneNumber = `${this.state.dialCode}${customer.phoneNumber}`
        const updated = customer.id
        customer.clientId = this.props.client.id

        const savedCustomer = await customersActions.saveCustomer(customer)
        // update status
        if (this.state.customerStatus !== customer.status) {
          customersActions.changeStatus(savedCustomer.id, customer.status)
        }

        message.success(`Customer was ${updated ? 'updated' : 'created'}`)
        this.setState({ customer: baseCustomer })
        history.replace('/manage/customers')
      } else {
        message.error('Wrong data')
      }
    } catch (error) {
      message.error(error.message)
    }
  }

  componentDidMount = async () => {
    await this.fetchCities()

    if (this.props.location.state && this.props.location.state.customer) {
      const customer = this.props.location.state.customer
      if (customer.country) {
        const country = countries.find(country => country.name === customer.country)

        if (country) {
          if (customer.phoneNumber && customer.phoneNumber.indexOf(country.dial_code) === 0) {
            customer.phoneNumber = customer.phoneNumber.replace(country.dial_code, '')
          }
          this.setState({ dialCode: country.dial_code })
        }
      }
      this.setState({
        customer: customer,
        edit: true,
        customerStatus: customer.status,
        tagLists: this.props.tags || [],
        tags: [...new Set(customer.tags.map(tag => tag.text))],
      })
    }
    setPageProps({ title: 'Create a new customer' })
  }

  fetchCities = async () => {
    try {
      const cities = await citiesActions.getCities(this.state.cityParams)
      this.setState({ cities: cities })
    } catch (error) {}
  }

  searchCities = async name => {
    this.setState({ cityParams: { ...this.state.cityParams, name: name } }, async () => await this.fetchCities())
  }

  change = async (field, value) => {
    if (field === 'country') {
      const country = countries.find(country => country.name === value)
      this.setState({ dialCode: country.dial_code })
    }
    if (field === 'city') {
      this.setState(
        {
          customer: { ...this.state.customer, [field]: value },
          cityParams: { ...this.state.cityParams, name: value },
        },
        await this.fetchCities()
      )
    } else {
      this.setState({ customer: { ...this.state.customer, [field]: value } })
    }
  }

  render() {
    const street = (
      <div className={styles.inputGroup}>
        <span className={styles.label}>Street</span>
        <div>
          <Input
            className={this.state.errors.street ? styles.errorInput : styles.textInput}
            onChange={e => this.change('street', e.target.value)}
            value={this.state.customer.street}
          />
        </div>
      </div>
    )
    const countriesOpts = countries.map(country => <Select.Option key={country.name}>{country.name}</Select.Option>)
    const citiesOption = this.state.cities.map(city => <Select.Option key={city.name}>{city.name}</Select.Option>)
    return (
      <div className={styles.customers}>
        <div className={styles.content}>
          <div className={styles.formGroup}>
            <div className={styles.block}>
              <span className={styles.label}>First Name</span>
              <Input
                className={this.state.errors.firstName ? styles.errorInput : styles.textInput}
                onChange={e => this.change('firstName', e.target.value)}
                value={this.state.customer.firstName}
              />
              <span className={styles.label}>Last name</span>
              <Input
                className={this.state.errors.lastName ? styles.errorInput : styles.textInput}
                onChange={e => this.change('lastName', e.target.value)}
                value={this.state.customer.lastName}
              />
              <div className={styles.bdLine}>
                <Select
                  className={this.state.errors.gender ? styles.errorSelectStatus : styles.selectStatus}
                  onChange={e => this.change('gender', e)}
                  value={
                    this.state.customer.gender || this.state.customer.gender === 0
                      ? this.state.customer.gender.toString()
                      : null
                  }
                >
                  <Select.Option key={1} value="0">
                    Male
                  </Select.Option>
                  <Select.Option key={2} value="1">
                    Female
                  </Select.Option>
                </Select>
                <DatePicker
                  className={this.state.errors.birthdayDate ? styles.errorDatePicker : styles.datePicker}
                  onChange={e => this.change('birthdayDate', e ? moment(e).format() : null)}
                  value={this.state.customer.birthdayDate ? moment(this.state.customer.birthdayDate) : null}
                />
              </div>
              <span className={styles.label}>Email</span>
              <Input
                className={this.state.errors.email ? styles.errorInput : styles.textInput}
                onChange={e => this.change('email', e.target.value)}
                value={this.state.customer.email}
              />
            </div>
            <div className={styles.block}>
              <div className={styles.inputBlock}>
                <div className={styles.inputGroup}>
                  <span className={styles.label}>Country</span>
                  <Select
                    className={this.state.errors.country ? styles.selectErrorInput : styles.selectInput}
                    onChange={e => this.change('country', e)}
                    value={this.state.customer.country}
                  >
                    {countriesOpts}
                  </Select>
                  {/* <Input
                    className={this.state.errors.country ? styles.errorInput : styles.textInput}
                    onChange={e => this.change('country', e.target.value)}
                    value={this.state.customer.country}
                  /> */}
                </div>
                <div className={styles.inputGroupRight}>
                  <span className={styles.label}>City</span>
                  {this.state.customer.country === 'ישראל' || false ? (
                    <Select
                      showSearch
                      className={this.state.errors.city ? styles.errorInput : styles.selectInput}
                      onChange={e => this.change('city', e)}
                      onSearch={e => this.searchCities(e)}
                      value={this.state.customer.city}
                      showArrow={false}
                      filterOption={false}
                      notFoundContent={null}
                    >
                      {citiesOption}
                    </Select>
                  ) : (
                    <Input
                      className={this.state.errors.city ? styles.errorInput : styles.textInput}
                      value={this.state.customer.city}
                      onChange={e => this.change('city', e.target.value)}
                    />
                  )}
                </div>
              </div>

              <div className={styles.inputBlock}>
                <div className={styles.inputGroup}>
                  {isTablet && street}
                  <span className={styles.label}>House number</span>
                  <Input
                    className={this.state.errors.houseNumber ? styles.errorInput : styles.textInput}
                    onChange={e => this.change('houseNumber', e.target.value)}
                    value={this.state.customer.houseNumber}
                  />
                </div>
                <div className={styles.inputGroupRight}>
                  <span className={styles.label}>Apartment</span>
                  <Input
                    className={this.state.errors.appartment ? styles.errorInput : styles.textInput}
                    onChange={e => this.change('appartment', e.target.value)}
                    value={this.state.customer.appartment}
                  />
                </div>
              </div>
              <div className={styles.lastLine}>
                <div className={styles.mobile}>
                  <span className={styles.label}>Mobile number</span>
                  <Tooltip placement="topLeft" title="Mobile number without country code ">
                    <Input
                      type="numeric"
                      prefix={
                        <span className={styles.dialCode}>{this.state.dialCode ? `+ ${this.state.dialCode}` : ''}</span>
                      }
                      // placeholder="mobile number without country code"
                      className={this.state.errors.phoneNumber ? styles.phoneErrorInput : styles.phoneInput}
                      onChange={e => this.change('phoneNumber', e.target.value)}
                      value={this.state.customer.phoneNumber}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            {!isTablet && (
              <div className={styles.block}>
                <div className={styles.inputBlock}>{street}</div>
              </div>
            )}
          </div>
          <div className={styles.formGroupLine}>
            <div className={styles.statusTagsBlock}>
              <div className={styles.statusGroup}>
                <span className={styles.label}>Status</span>
                <Select
                  className={styles.selectStatus}
                  onChange={val => this.setState({ customer: { ...this.state.customer, status: val } })}
                  value={CUSTOMER_STATUSES[this.state.customer.status]}
                >
                  {Object.keys(CUSTOMER_STATUSES).map(key => (
                    <Select.Option value={key}>{CUSTOMER_STATUSES[key]}</Select.Option>
                  ))}
                </Select>
              </div>
              <div className={styles.tagsGroup}>
                <span className={styles.label}>Tags</span>
                <div className={styles.tagsGroup}>
                  <TagsList tags={this.state.tags} onUpdateTags={tags => this.setState({ tags: tags })} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.saveBlock}>
            <div className={styles.saveLine}>
              <Button className={styles.saveButton} block onClick={() => this.submit()}>
                Save
              </Button>
            </div>

            <div className={styles.space} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({ client: state.client, tags: state.tags }))(Customer)
