import React, { Component } from 'react'
import { Input, Button, Upload, message } from 'antd'
import setPageProps from 'actions/pageProps'
import { uploadFile } from 'actions/file'
// import { isSupportedFileType } from 'utils'
import * as campaignActions from 'actions/campaign'

import { connect } from 'react-redux'
import history from 'utils/history'

import styles from './styles.module.scss'

class Campaign extends Component {
  state = {
    campaign: {
      clientId: null,
      name: null,
      text: null,
      imageUrl: null,
      url: null,
    },
    fields: ['name', 'text'],
    errors: {},
  }

  componentDidMount = async () => {
    try {
      let campaign = {}
      if (this.props.location.state && this.props.location.state.campaign) {
        campaign = this.props.location.state.campaign
        this.setState({ campaign: campaign, edit: true })
      }

      this.setState({ campaign: { ...campaign } })
      setPageProps({ title: 'Create a new campaign' })
    } catch (error) {
      message.error(error.message)
    }
  }

  upload = async file => {
    try {
      let formData = new FormData()
      formData.append('formFile', file)
      const fileURL = await uploadFile(formData)
      this.setState({ campaign: { ...this.state.campaign, imageUrl: fileURL } })
    } catch (error) {
      message.error(error.message)
    }
  }

  uploadProps = {
    accept: 'image/*',
    showUploadList: false,
    customRequest: () => {},
    beforeUpload: async file => {
      // if (isSupportedFileType(file.type)) {
      await this.upload(file)
      // } else {
      //   message.error('Unsupported file type', 5)
      // }
    },
  }

  change = (field, value) => {
    this.setState({ campaign: { ...this.state.campaign, [field]: value } })
  }

  submit = async () => {
    let errors = {}
    let { campaign } = this.state
    this.state.fields.forEach(key => {
      if (!campaign[key]) {
        errors[key] = true
      }
    })
    const clientId = this.props.client.id
    this.setState({ errors: errors })
    try {
      if (!Object.keys(errors).length && clientId) {
        // const redirectUrl = campaign.url
        // ? `${process.env.REACT_APP_HOST}Redirect?returnUrl=${encodeURIComponent(
        //     campaign.url.concat(`&clientId=`, clientId)
        //   )}`
        // : null
        campaign = { ...campaign, clientId: clientId }
        //  if (redirectUrl) {
        //   campaign.url = redirectUrl
        // }
        await campaignActions.saveCampaign(campaign)
        message.success('Campaign saved', 5)
        history.push('campaigns')
      }
    } catch (error) {
      message.error(error.message)
    }
  }
  render() {
    return (
      <div className={styles.campaigns}>
        <div className={styles.content}>
          <div className={styles.formGroup}>
            <div className={styles.block}>
              <span className={styles.label}>Title</span>
              <Input
                className={this.state.errors.name ? styles.errorInput : styles.textInput}
                onChange={e => {
                  this.change('name', e.target.value)
                }}
                value={this.state.campaign.name}
              />
              <span className={styles.label}>Description</span>
              <Input.TextArea
                className={this.state.errors.text ? styles.errorDescription : styles.description}
                onChange={e => {
                  this.change('text', e.target.value)
                }}
                value={this.state.campaign.text}
              />

              <span className={styles.label}>Link</span>
              <Input
                className={this.state.errors.url ? styles.errorInput : styles.textInput}
                onChange={e => {
                  this.change('url', e.target.value)
                }}
                value={this.state.campaign.url}
              />
            </div>
            <div className={styles.blockRight}>
              <span className={styles.label}>Picture</span>
              <Upload {...this.uploadProps} className={styles.uploadContainer}>
                <img
                  className={styles.imgUpload}
                  src={this.state.campaign.imageUrl ? this.state.campaign.imageUrl : null}
                  alt=""
                />
              </Upload>
            </div>
          </div>
          <div className={styles.bottomGroup}>
            <div className={styles.block} />
            <div className={styles.blockRight}>
              <Button className={styles.saveButton} onClick={() => this.submit()}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({ client: state.client }))(Campaign)
