import React, { Component } from 'react'
import { Layout, Button } from 'antd'
import { isTablet } from 'react-device-detect'
import { Switch, Route } from 'react-router'

import withRouter from 'react-router/es/withRouter'
import styles from './styles.module.scss'
import Clients from './Clients'
import Industries from './Industries'
import Templates from './Templates'
import Header from 'components/Header'
import * as accountActions from 'actions/account'
import history from 'utils/history'
import Users from './Users'
import Reports from 'containers/Manage/Reports'

class Admin extends Component {
  render = () => {
    return (
      <Layout>
        <Layout.Sider className={styles.sider} width={315}>
          <div className={styles.title}>Admin</div>
          <div className={styles.buttons}>
            <Button onClick={() => history.push('/admin/clients')}>Clients</Button>
            <Button onClick={() => history.push('/admin/users')}>Users</Button>
            <Button onClick={() => history.push('/admin/industries')}>Industries</Button>
            <Button onClick={() => history.push('/admin/reports')}>Reports</Button>
            <Button onClick={() => history.push('/admin/templates')}>Templates</Button>
            <Button
              onClick={async () => {
                try {
                  await accountActions.logout()
                } catch (e) {
                  console.log(e)
                } finally {
                  history.push('/')
                }
              }}
            >
              Log out
            </Button>
          </div>
        </Layout.Sider>
        <Layout className={isTablet ? styles.tabletContent : styles.content}>
          <Header />
          <Layout className={styles.adminContent}>
            <Switch>
              <Route path="/admin/clients" component={Clients} />
              <Route path="/admin/users" component={Users} />
              <Route path="/admin/industries" component={Industries} />
              <Route path="/admin/reports" component={Reports} />
              <Route path="/admin/templates" component={Templates} />
              {/* <Redirect from="*" to="/admin/clients" /> */}
            </Switch>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

export default withRouter(Admin)
